<template>
  <el-dialog
    append-to-body
    :visible="dialogFlag"
    :before-close="handleClose"
    :title="isAdd ? '新增销售' : '编辑销售'"
    width="600px"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      size="small"
      label-width="90px"
    >
      <el-form-item label="销售类型" prop="type">
        <el-select
          :disabled="!isAdd"
          placeholder="请选择"
          v-model="form.type"
        >
          <el-option
            v-for="(item,index) in types"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="销售级别" prop="level">
        <el-select placeholder="请选择" v-model="form.level">
          <el-option
            v-for="(item,index) in levels"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="姓名/名称"
        prop="name"
      >
        <el-input
          placeholder="请输入姓名/名称"
          v-model="form.name"
          :disabled="!isAdd"
        />
      </el-form-item>
      <el-form-item label="编码" v-if="!isAdd">
        <el-input
          :disabled="true"
          v-model="form.code"
          />
      </el-form-item>
      <el-form-item
        label="上级销售"
        prop="superior"
      >
        <el-autocomplete
          class="el-autocomplete-box"
          v-model="form.superior"
          :fetch-suggestions="querySearchAsync"
          placeholder="请输入内容"
        >
          <template slot-scope="{ item }">
            <div>{{ item.value=item.name }}</div>
          </template>
        </el-autocomplete>
      </el-form-item>
      <el-form-item
        label="手机号码"
        prop="phone"
      >
        <el-input
          v-model="form.phone"
          placeholder="请输入手机号码"
          />
      </el-form-item>
      <el-form-item
        label="公司邮箱"
        prop="email"
      >
        <el-input
          placeholder="请输入公司邮箱"
          v-model="form.email"
        />
      </el-form-item>
      <el-form-item
        label="登录邮箱"
        prop="loginEmail"
      >
        <el-input
          placeholder="请输入公司邮箱"
          v-model="form.loginEmail"
          :disabled="isAdd ? false: true"
        />
      </el-form-item>
      <el-form-item
        label="入职时间"
        prop="entryTime"
      >
        <el-date-picker
          v-model="form.entryTime"
          type="date"
          placeholder="入职时间"
          style="width: 180px;"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item
        label="就职状态"
        prop="status"
      >
        <el-select
          placeholder="请选择"
          v-model="form.status"
        >
          <el-option
            v-for="(item,index) in statusList"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { isMobile,validatEmail } from '@/utils/validate';
import { addManger, editManger, queryManger } from '@/api/alitatech/manager';

export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true
    },
    dialogFlag: {
      type: Boolean,
      required: false
    },
    formData:{
      type: Object,
      default(){
        return {
          id: '',
          type: '',
          name: '',
          level: '',
          code: '',
          superior: '',
          phone: '',
          email: '',
          loginEmail: '',
          entryTime: '',
          status: '',
        }
      }
    }
  },
  data() {
    let validatorPhone = (rule,value,callback) => {
      if(value == ''){
        return callback(new Error('请输入手机号码'));
      }else {
        setTimeout(() => {
          if(!isMobile(value)){
            return callback(new Error('请输入正确的手机号码'));
          }else {
            callback();
          }
        },1000);
      };
    };
    let validatorEmail = (rule,value,callback) => {
      if(value == ''){
        return callback(new Error('请输入邮箱'));
      }else {
        setTimeout(() => {
          if(!validatEmail(value)){
            return callback(new Error('请输入正确的邮箱'));
          }else {
            callback();
          }
        },1000);
      }
    };
    return {
      loading: false,
      dialog: false,
      types:[
        {label:'客户经理',value:1},
        {label:'居间人',value:2},
      ],
      levels:[
        {label:'高级',value:3},
        {label:'中级',value:2},
        {label:'初级',value:1},
      ],
      salesManagementList:[],
      statusList:[
        {label: '在职',value: 0},
        {label: '离职',value: 1},
      ],
      form: {
        id: '',
        type: '',
        level: '',
        code: '',
        superior: '',
        name: '',
        phone: '',
        email: '',
        loginEmail: '',
        entryTime: '',
        status: '',
      },
      rules:{
        type:[
          { required: true,message: '请选择销售类型',trigger: 'blur' },
        ],
        level:[
          { required: true,message: '请选择销售级别',trigger: 'blur' },
        ],
        // code:[
        //   { required: true,message: '请选择销售级别',trigger: 'blur' },
        // ],
        superior:[
          { required: true,message: '请选择上级销售',trigger: 'change' },
        ],
        name:[
          { required: true,message: '请输入姓名/名称',trigger: 'blur' },
        ],
        phone:[
          { required: true,message: '请输入手机号码',trigger: 'blur' },
          { validator: validatorPhone,trigger: 'blur' },
        ],
        email:[
          { required: true,message: '请输入公司邮箱',trigger: 'blur' },
          { validator: validatorEmail,trigger: 'blur' },
        ],
        loginEmail:[
          { required: true,message: '请输入登录邮箱',trigger: 'blur' },
          { validator: validatorEmail,trigger: 'blur' },
        ],
        entryTime:[
          { required: true,message: '请选择入职时间',trigger: 'blur' },
        ],
        status:[
          { required: true,message: '请选择就职状态',trigger: 'blur' },
        ],
      },
      allManager: [],
    }
  },
  created(){
    this.assig();
    this.getAllManager();
  },
  watch:{
    formData:{
      handler(){
        this.assig();
      },
      deep:true
    },
    dialogFlag(newValue){
      if(newValue && this.isAdd){
        this.resetForm();
      }
      if(newValue){
        this.$nextTick(() => {
          this.$refs.form.clearValidate();
        });
      }
    }
  },
  methods: {
    getCurrentUpCode(upName) {
      let codeIndex = this.allManager.findIndex((item) => {
        return item.name == upName;
      });
      if(codeIndex > -1){
        return this.allManager[codeIndex].code;
      }
    },
    getAllManager(){
      queryManger({
        name: '',
      }).then((res) => {
        this.allManager = res;
      });
    },
    querySearchAsync(queryString, cb) {
      queryManger({
        name: queryString,
      }).then((res) => {
        this.salesManagementList = res;
      });

      this.timeout = setTimeout(() => {
        cb(this.salesManagementList);
      }, 1000);
    },
    assig(){
      this.form = JSON.parse(JSON.stringify(this.formData));
    },
    close(){
      this.$emit('close');
    },
    cancel() {
      this.$emit('close');
    },
    handleClose(){
      this.close();
    },
    doSubmit() {
      this.$refs.form.validate((valid) => {
        if(valid){
          this.loading = true;
          if (this.isAdd) {
            this.doAdd()
          } else this.doEdit();
        }else {
          console.log('没有通过');
        }
      })
    },
    doAdd() {
      delete this.form.id;
      addManger({
        type: this.form.type,
        level: this.form.level,
        upCode: this.getCurrentUpCode(this.form.superior),
        name: this.form.name,
        phone: this.form.phone,
        emailCompany: this.form.email,
        emailLogin: this.form.loginEmail,
        workDate: this.form.entryTime,
        status: this.form.status,
      }).then((res) => {
        this.resetForm();
        this.close();
        this.$notify({
          title: '添加成功',
          type: 'success',
          duration: 2500
        });
        this.loading = false;
        this.$parent.init();
      }).catch(err => {
        this.loading = false;
        console.log(err.response.data.message);
      });
    },
    doEdit() {
      // 修改要填id
      debugger;
      editManger({
          id:this.form.id,
          type: this.form.type,
          level: this.form.level,
          name: this.form.name,
          code: this.form.code,
          upCode: this.getCurrentUpCode(this.form.superior),
          phone: this.form.phone,
          emailCompany: this.form.email,
          emailLogin: this.form.loginEmail,
          workDate: this.form.entryTime,
          status: this.form.status,
        }).then(res => {
          this.resetForm();
          this.$notify({
            title: '修改成功',
            type: 'success',
            duration: 2500
          });
          this.close();
          this.loading = false;
          this.$parent.init();
        }).catch(err => {
          this.loading = false;
        });
    },
    resetForm() {
      this.$refs.form.resetFields();
      this.form = {
        type: '',
        name: '',
        level: '',
        code: '',
        superior: '',
        phone: '',
        email: '',
        loginEmail: '',
        entryTime: '',
        status: '',
      };
    }
  }
}
</script>

<style scoped>
  .logo-class{
  height: 100px;
  width: 200px;
  margin-top: 10px;
}
</style>
