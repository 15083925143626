<template>
  <el-dialog
    append-to-body
    :visible="dialogFlag"
    :before-close="handleClose"
    width="1000px"
  >
    <blockquote class="my-blockquote">销售详情</blockquote>
    <el-form
      label-width="90px"
      label-position="top"
    >
      <el-form-item label="基本信息">
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item>
              <p>编码: {{form.code}}</p>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <p>销售级别: {{getLevel(form.level)}}</p>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <p>姓名/名称: {{form.name}}</p>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <p>销售类型: {{getType(form.type)}}</p>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item>
              <p>手机号码: {{form.phone}}</p>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <p>公司邮箱: {{form.emailCompany}}</p>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <p>登录邮箱: {{form.emailLogin}}</p>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item>
              <p>入职时间: {{parseTime(form.workDate).split(' ')[0]}}</p>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <p>就职状态: {{getStatus(form.status)}}</p>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <p>上级销售: {{form.upName}}</p>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="客户邀请">
        <el-row class="content" :gutter="30">
          <el-col :span="8">
            <div class="item-link item">
              <div
                class="mar-bot10 fs16 blod line-text display-flex flex-start"
              >
                <span class="mar-right5">邀请链接</span>
                <img
                  :src="qrcodeUrl"
                     class="pointer copy-icon"
                     alt=""
                     data-clipboard-target="#regLink"
                  id="copylink"
                >
              </div>
              <div class="link-bot">
                <div class="line-text-flow mar-right10" id="regLink">{{form.invitationUrl}}</div>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="Invitation-code item">
              <div class="mar-bot10 fs16 blod line-text display-flex flex-start">
                <span class="mar-right5">邀请码</span>
                <img :src="qrcodeUrl" class="pointer copy-icon" data-clipboard-target="#invitation" alt="" id="copycode">
              </div>
              <div class="link-bot">
                <div class="mar-right10 line-text-flow" id="invitation">{{form.invitationCode}}</div>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="qr-code item">
              <div class="qr-code-left">
                <div class="mar-bot10 fs16 blod line-text display-flex flex-start">
                  <span class="mar-right5">二维码</span>
                  <div class="theme-redfont pointer" data-clipboard-target="#qrimgouter" id="copyqrbtn">
                    <img :src="qrcodeUrl" alt="">
                  </div>
                </div>
              </div>
              <div class="qr-img">
                <div class="qr-img-outer" id="qrimgouter">
                  <img class="qrimg" :src="form.invitationQr" alt="">
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="品牌LOGO">
        <img
          v-if="form.logoUrl"
          class="logo-box"
          :src="form.logoUrl"
        />
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
  import Clipboard from 'clipboard';
  import qrcodeUrl from '@/assets/img/copy.png'
  import { parseTime } from '@/utils'

  export default {
  props: {
    dialogFlag: {
      type: Boolean,
      required: false
    },
    form:{
      type: Object,
      default(){
        return {
          id: '',
          type: '',
          level: '',
          code: '',
          upName: '',
          phone: '',
          emailCompany: '',
          emailLogin: '',
          workDate: '',
          status: 0,
        }
      }
    }
  },
  data() {
    return {
      qrcodeUrl
    }
  },
  mounted(){
    this.clipboard = new Clipboard('#copyqrbtn');
    var _this = this;
    this.clipboard.on('success', function(e) {
      console.log(e);
      _this.$message({
        message: '复制成功',
        type: 'success'
      });
      e.clearSelection();
    });
    this.clipboard.on('error', function(e) {
      e.clearSelection();
    });
    this.clipboardCode = new Clipboard('#copycode');
    var _this = this;
    this.clipboardCode.on('success', function(e) {
      console.log(e);
      _this.$message({
        message: '复制成功',
        type: 'success'
      });
      e.clearSelection();
    });
    this.clipboardCode.on('error', function(e) {
      e.clearSelection();
    });
    this.clipboardLink = new Clipboard('#copylink');
    var _this = this;
    this.clipboardLink.on('success', function(e) {
      console.log(e);
      _this.$message({
        message: '复制成功',
        type: 'success'
      });
      e.clearSelection();
    });
    this.clipboardLink.on('error', function(e) {
      e.clearSelection();
    });
  },
  destroyed () {
    this.clipboard.destroy();
    this.clipboardCode.destroy();
    this.clipboardLink.destroy();
  },
  methods: {
    parseTime,
    close(){
      this.$emit('close');
    },
    handleClose(){
      this.close();
    },
    getType(type){
      switch (type) {
        case 1:
          return '客户经理';
          break;
        case 2:
          return '居间人';
          break;
        default:
          return '客户经理';
      }
    },
    getLevel(level){
      switch (level) {
        case 1:
          return '初级';
          break;
        case 2:
          return '中级';
          break;
        case 3:
          return '高级';
          break;
        default:
          return '初级';
      }
    },
    getStatus(status){
      switch (status) {
        case 0:
          return '在职';
          break;
        case 1:
          return '离职';
          break;
        default:
          return '在职';
      }
    },
  }
}
</script>

<style lang="less" scoped>
  .item {
    &.qr-code {
      align-items: flex-start;

      .qr-img-outer {
        width: 80px;
        height: 80px;
        border: 1px solid rgba(230, 230, 230, 1);
        border-radius: 4px;
        overflow: hidden;

        .qrimg {
          width: 100%;
          height: 100%;
        }
      }
    }

    .line-text {
      text-align: left;
    }

    .link-bot {
      padding-left: 20px;
      background: #FAFAFA;
      border: 1px solid rgba(230, 230, 230, 1);
      border-radius: 5px;
      margin-right: 20px;
    }
  }
  .logo-box {
    height: 28px;
    max-width: 200px;
  }
</style>
