<template>
  <div class="app-container">
    <eHeader :query="query" @add-click="addHandle"/>
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="id" label="编号"/>
      <el-table-column prop="name" label="姓名/名称">
        <template slot-scope="scope">
          <p style="white-space: pre;">{{scope.row.name}}</p>
        </template>
      </el-table-column>
      <el-table-column prop="level" label="销售级别">
        <template slot-scope="scope">
          <span>{{getLevel(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="type" label="销售类型">
        <template slot-scope="scope">
          <span>{{getType(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="upName" label="上级销售"/>
      <el-table-column prop="phone" label="手机号码"/>
      <el-table-column width="200" prop="emailCompany" label="公司邮箱"/>
      <el-table-column width="200" prop="emailLogin" label="登录邮箱"/>
      <el-table-column prop="workDate" label="入职时间">
        <template slot-scope="scope">
          <span>{{parseTime(scope.row.workDate).split(' ')[0]}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="在职状态">
        <template slot-scope="scope">
          <span>{{getStatus(scope.row.status)}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="350px"
        align="center"
      >
        <template slot-scope="scope">
          <el-button v-if="checkPermission(['ADMIN','SrPortalManager_ALL','SrPortalManager_EDIT'])" type="success" size="small" @click="editHandle(scope.row)">编辑</el-button>
          <el-button v-if="checkPermission(['ADMIN','SrPortalManager_ALL','SrPortalManager_SELECT'])" type="primary" size="small" @click="lookHandle(scope.row)">查看</el-button>
          <el-button v-if="checkPermission(['ADMIN'])" type="danger" size="small" @click="deleteHandle(scope.row)">删除</el-button>
          <div class="upload-box"
               v-if="getUpLoadFlag(scope.row)"
               @click="uploadOuter(scope.row)"
          >
            <el-upload
              :action="uploadUrl"
              :on-success="uploadIntermediary"
              :show-file-list="false"
            >
              <el-button v-if="checkPermission(['ADMIN','SrPortalManager_ALL','SrPortalManager_EDIT'])" type="warning" size="small">上传</el-button>
            </el-upload>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination
      v-if="total"
      :total="total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"/>
    <add-or-edit-dialog
      :is-add="isAdd"
      :dialog-flag="dialogFlag"
      :form-data="currentData"
      @close="dialogFlag = false"
    ></add-or-edit-dialog>
    <detail-dialog
      :dialog-flag="detailDialog"
      :form="currentData"
      @close="detailDialog = false"
    ></detail-dialog>
  </div>
</template>

<script>
  import { uploadLogo, deleteManger } from '@/api/alitatech/manager'
  import checkPermission from '@/utils/permission'
  import initData from '@/mixins/initData'
  import { parseTime } from '@/utils/index'
  import eHeader from '@/components/alitatech/salesManagement/header'
  import addOrEditDialog from '@/components/alitatech/salesManagement/form'
  import detailDialog from '@/components/alitatech/salesManagement/detial'

  export default {
    name: 'salesManagement',
    components: {
      eHeader,
      addOrEditDialog,
      detailDialog
    },
    mixins: [initData],
    data() {
      return {
        delLoading: false,
        sup_this: this,
        currentData: {
          type: '',
          level: '',
          superior: '',
          phone: '',
          email: '',
          loginEmail: '',
          entryTime: '',
          status: ''
        },
        dialogFlag: false,
        detailDialog: false,
        isAdd: false,
        uploadUrl: process.env.VUE_APP_API + 'community/auth/file/upload',
        currentManagerId: ''
      }
    },
    created() {
      this.$nextTick(() => {
        this.init()
      })
    },
    methods: {
      parseTime,
      checkPermission,
      addHandle() {
        this.isAdd = true
        this.dialogFlag = true
      },
      beforeInit() {
        this.url = '/portal/crm/manager/query'
        const sort = 'id,desc'
        this.params = {
          page: this.page,
          size: this.size,
          sort: sort
        }
        if (this.query.type) {
          this.params.type = this.query.type
        }

        if (this.query.value) {
          this.params.name = this.query.value
        }

        if (this.query.status !== '') {
          this.params.status = this.query.status
        }

        return true
      },
      getLevel(row) {
        switch (row.level) {
          case 1:
            return '初级'
            break
          case 2:
            return '中级'
            break
          case 3:
            return '高级'
            break
          default:
            return '初级'
        }
      },
      getType(row) {
        switch (row.type) {
          case 1:
            return '客户经理'
            break
          case 2:
            return '居间人'
            break
          default:
            return '客户经理'
        }
      },
      getUpLoadFlag(row) {
        return row.type == 2 && row.level == 3
      },
      getStatus(status) {
        switch (status) {
          case 0:
            return '在职'
            break
          case 1:
            return '离职'
            break
          default:
            return '在职'
        }
      },
      editHandle(row) {
        this.isAdd = false
        this.currentData = {
          id: row.id,
          type: row.type,
          level: row.level,
          name: row.name,
          superior: row.upName,
          code: row.code,
          phone: row.phone,
          email: row.emailCompany,
          loginEmail: row.emailLogin,
          entryTime: row.workDate,
          status: row.status
        }
        this.dialogFlag = true
      },
      lookHandle(row) {
        this.currentData = row
        this.detailDialog = true
      },
      deleteHandle(row) {
        this.$confirm(`是否要删除这个销售`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          //点击确定的操作(调用接口)
          deleteManger({
            id: row.id
          }).then((res) => {
            this.init()
            this.$message({
              type: 'success',
              message: '删除成功'
            });
          })
        }).catch(() => {
          //几点取消的提示
        })
      },
      uploadOuter(row) {
        this.currentManagerId = row.id
      },
      uploadIntermediary(response) {
        //上传成功要处理的事
        uploadLogo({
          logoUrl: response.data.content[0].url,
          id: this.currentManagerId
        }).then((res) => {
          this.init()
          this.$notify({
            title: '上传成功',
            type: 'success'
          })
        })
      }
    }
  }
</script>

<style scoped>
  .upload-box {
    display: inline-block;
    margin-left: 10px;
  }
</style>
